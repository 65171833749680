export enum Variants {
    Default = 'default',
    ProductInfo = 'product-info',
    ProductStages = 'product-stages',
}

export interface Props {
    className?: string;
    html: string;
    expandOnClick?: () => void;
    expandDescription?: boolean;
    variant?: Variants;
}
