import ApiProductCategory from 'types/api/ProductCategory';
import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';
import ListCollection from 'types/redux/ListCollection';

import ProductCategory from 'models/ProductCategory';

import { LIST_MARKUP } from 'consts/redux';
import { withVariables } from 'utils/string';
import { request } from 'services/Api';

export interface ListParams {
    page?: number;
    perPage?: number;
}
export const list = async (params?: ListParams): Promise<ListCollection<ProductCategory>> => {
    const response = await request({
        method: 'GET',
        path: withVariables(Endpoints.PublicProductCategories, params),
        requestParams: params,
    });

    const data = response?.payload?.data;
    const resource = data && data[ApiResources.ProductCategories];

    if(!data || !resource) {
        throw new Error('Not Found');
    }

    return {
        ...LIST_MARKUP,
        isLoaded: true,
        elements: resource
            .elements
            .map((element: ApiProductCategory) => new ProductCategory(element)),
        meta: resource.meta,
    };
};
