import { FunctionComponent } from 'react';
import getConfig from 'next/config';
import classnames from 'classnames';

import Button, { ButtonTypes } from 'components/layout/Button';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutHtmlStyledContent: FunctionComponent<Props> = ({ html, className, expandOnClick, expandDescription, variant }) => {
    const { publicRuntimeConfig } = getConfig();
    const isPL = publicRuntimeConfig.APP_LOCALE === 'pl';

    return (
        <StyledComponent
            className={classnames(
                'layout-html-styled-content',
                className,
                `variant-${variant}`
            )}
        >
            <div
                className="description-content"
                dangerouslySetInnerHTML={{ __html: html }}
            />
            {expandOnClick && (
                <Button
                    type={ButtonTypes.Button}
                    onClick={expandOnClick}
                    className="show-more-button"
                >
                    {isPL
                        ? expandDescription
                            ? 'Zwiń' : 'Czytaj dalej'
                        : expandDescription
                            ? 'Collapse' : 'Read more'
                    }
                </Button>
            )}
        </StyledComponent>
    );
};

export default LayoutHtmlStyledContent;
