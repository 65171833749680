import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	text-align: justify;
	color: #111111;

	.show-more-button {
		padding: 1em;
	}

	* {
		font-family: ${vars.fontPrimary} !important;
	}

	h1 {
		font-size: 25px;
		font-weight: 700;
		margin-bottom: 1em;
		color: rgb(178, 144, 81);
	}

	h2 {
		font-size: 28px;
		font-weight: 300;
		margin: 1em 0;
		color: rgb(178, 144, 81);
	}

	h3 {
		font-size: 32px;
		font-weight: 300;
		margin-top: 2em;
		color: #272626;
	}

	h4 {
		font-size: 19px;
		font-weight: 700;
		margin: 2em 0;
		color: #000000;
	}

	h5 {
		font-size: 17px;
		font-weight: 700;
		margin-bottom: 0.25em;
		color: #000000;
	}

	ul {
		margin: 1em 0;
		list-style-type: disc;
	}

	ol {
		margin: 1em 0;
		list-style-type: decimal;
	}

	li {
		margin-left: 1.5em;
	}

	a {
		color: #b29051;
		text-decoration: underline;
	}

	i {
		font-style: italic;
	}

	b,
	strong {
		color: #111111;
		font-weight: 700;
	}

	p {
		line-height: 22px !important;
		font-size: 1em;
		color: #272626 !important;
		background-color: transparent !important;

		span {
			line-height: 22px !important;
			font-size: 1em;
			color: #272626 !important;
			background-color: transparent !important;
		}
	}

	img {
		height: auto;
		max-width: 100%;
		border-radius: 0.5em;
		width: 100%;
		margin: 2em auto;
	}

	pre,
	code {
		padding: 1em;
		background-color: #333333;
		color: white;
	}

	iframe {
		width: 100%;
		height: 100%;
		min-height: 40vh;
	}

	// Variant: ProductInfo
	&.variant-product-info {
		p {
			color: ${vars.colorTextGray} !important;
			font-weight: 600;

			span {
				color: ${vars.colorTextGray} !important;
				font-weight: 600;
			}
		}

		ul,
		ol {
			padding: 0;
			margin: 0 0 0.5em 0.4em;

			li {
				margin: 0 0 0.5em 1em;
			}

			li,
			li > span {
				background-color: transparent !important;
				text-align: left;
				font-size: 16px !important;
				color: ${vars.colorTextGray} !important;
			}
		}

		p {
			font-size: 16px !important;
		}

		ul {
			li {
				&::marker {
					color: #ba9449;
					font-size: 1.4em;
					padding-left: 1em;
				}
			}
		}

		ol {
			li {
				&::marker {
					color: #ba9449;
					font-size: 1em;
					padding-left: 1em;
				}
			}
		}
	}

	// Variant: ProductStages
	&.variant-product-stages {
		ul,
		ol {
			padding: 0;
			margin: 0 0 0.5em 0.5em;

			li {
				margin: 0 0 1em 1em;
			}

			li,
			li > span {
				background-color: transparent !important;
				text-align: left;
				font-size: 16px !important;
				color: ${vars.colorTextGray} !important;
			}
		}

		ul {
			li {
				&::marker {
					color: ${vars.colorWhite};
					font-size: 1.4em;
				}
			}
		}

		ol {
			li {
				&::marker {
					color: ${vars.colorWhite};
					font-size: 1em;
				}
			}
		}

		p {
			font-size: 16px !important;
		}
	}

	@media all and (max-width: ${vars.desktopXS}) {
		iframe {
			min-height: 35vh;
		}
	}
	@media all and (max-width: ${vars.tabletM}) {
		text-align: left;

		.description-content {
			text-align: left;
		}

		iframe {
			min-height: 30vh;
		}
	}

	@media all and (max-width: ${vars.tabletS}) {
		// Variant: ProductInfo
		&.variant-product-info {
			ul {
				li,
				li > span {
					font-size: 14px !important;
				}
			}

			p {
				font-size: 14px !important;
			}
		}

		// Variant: ProductStages
		&.variant-product-stages {
			ol,
			ul {
				margin: 0 0 0.5em 0em;
			}
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
		iframe {
			min-height: 20vh;
		}
	}
`;
